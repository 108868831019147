<template>
  <div class="post-edit-container group">
    <div class="page__title">
      <svg-icon icon-class="icon-plus-green" color="#027711"></svg-icon>
      <p><strong>小组发布</strong></p>
    </div>
    <!-- form框 -->
    <div class="edit-form-container">
      <van-form @submit="onSubmit" class="post-edit-form">
        <div class="form__item">
          <van-field
            v-model="form.content"
            class="textarea"
            type="textarea"
            maxlength="500"
            placeholder="分享给小组同伴…"
            show-word-limit
            :rules="[
              {
                required: true,
                message: '请填写内容'
              }
            ]"
          >
            <template #label>
              <p><span class="font-red">1、</span>编辑内容</p>
            </template>
          </van-field>
        </div>
        <div class="form__item">
          <van-field>
            <template #input>
              <wx-upload prefix="group-post" :limit="9" @onChange="onUploadChange"></wx-upload>
            </template>
          </van-field>
        </div>
        <div class="form__item">
          <van-field v-model="form.groupid" type="hidden" readonly clickable>
            <template #label>
              <p><span>2、</span>选择小组</p>
            </template>
          </van-field>
        </div>
        <!-- 底部导航 -->
        <div class="bottom-menu-container">
          <van-button class="menu__item gray form-cancel-bt" native-type="button" @click="goBack"
            >返回</van-button
          >
          <van-button class="menu__item form-submit-bt" :loading="requesting"
            ><strong>确认</strong></van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import wxUpload from '../common/_upload-bt.vue';

export default {
  name: 'page-group-post-edit',
  components: {
    wxUpload,
  },
  data() {
    return {
      isShowDatePicker: false,
      form: {
        content: '',
        imgs: '',
        groupid: '',
      },
      requesting: false,
    };
  },
  computed: {
    groupid() {
      return this.$route.params.id;
    },
    postid() {
      return this.$route.params.postid;
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(['ADD_GROUP_POST_CLEAR_COUNT', 'ADD_GROUP_POST_TOTAL_COUNT']),
    onUploadChange(val) {
      this.form.imgs = val.length > 0 ? val : '';
    },
    onSubmit() {
      const that = this;
      const { groupid } = that;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .post(`api/group/${groupid}/post/create`, {
            title: that.form.content,
            content: that.form.content,
            imgs: that.form.imgs,
          })
          .then((res) => {
            console.log(res);
            // 发帖成功
            // 更新解锁数量和总数
            that.ADD_GROUP_POST_CLEAR_COUNT(groupid);
            that.ADD_GROUP_POST_TOTAL_COUNT(groupid);
            that.goBack();
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onDatePickerConfirm(date) {
      console.log(date);
    },
  },
};
</script>

<style></style>
