var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-edit-container group"},[_c('div',{staticClass:"page__title"},[_c('svg-icon',{attrs:{"icon-class":"icon-plus-green","color":"#027711"}}),_vm._m(0)],1),_c('div',{staticClass:"edit-form-container"},[_c('van-form',{staticClass:"post-edit-form",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"form__item"},[_c('van-field',{staticClass:"textarea",attrs:{"type":"textarea","maxlength":"500","placeholder":"分享给小组同伴…","show-word-limit":"","rules":[
            {
              required: true,
              message: '请填写内容'
            }
          ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_c('span',{staticClass:"font-red"},[_vm._v("1、")]),_vm._v("编辑内容")])]},proxy:true}]),model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('div',{staticClass:"form__item"},[_c('van-field',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('wx-upload',{attrs:{"prefix":"group-post","limit":9},on:{"onChange":_vm.onUploadChange}})]},proxy:true}])})],1),_c('div',{staticClass:"form__item"},[_c('van-field',{attrs:{"type":"hidden","readonly":"","clickable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_c('span',[_vm._v("2、")]),_vm._v("选择小组")])]},proxy:true}]),model:{value:(_vm.form.groupid),callback:function ($$v) {_vm.$set(_vm.form, "groupid", $$v)},expression:"form.groupid"}})],1),_c('div',{staticClass:"bottom-menu-container"},[_c('van-button',{staticClass:"menu__item gray form-cancel-bt",attrs:{"native-type":"button"},on:{"click":_vm.goBack}},[_vm._v("返回")]),_c('van-button',{staticClass:"menu__item form-submit-bt",attrs:{"loading":_vm.requesting}},[_c('strong',[_vm._v("确认")])])],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("小组发布")])])
}]

export { render, staticRenderFns }